import { Application } from "@hotwired/stimulus"
import { appsignal, installErrorHandler } from "../appsignal/appsignal"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Configure appsignal error handling
if (appsignal) installErrorHandler(appsignal, application)

export { application }

import AbstractUploadController from "../../components/abstract_upload_controller.js"
application.register("abstract-upload", AbstractUploadController)

import ConfirmationModalController from "../../components/confirmation_modal_controller.js"
application.register("confirmation-modal", ConfirmationModalController)

import DropdownMenuComponentController from "../../components/dropdown_menu_component_controller.js"
application.register("dropdown-menu-component", DropdownMenuComponentController)

import DropdownMenuInstanceController from "../../components/dropdown_menu_instance_controller.js"
application.register("dropdown-menu-instance", DropdownMenuInstanceController)

import DatePickerController from "../../components/date_picker_controller.js"
application.register("date-picker", DatePickerController)

import DatePickerMobileController from "../../components/date_picker_mobile_controller.js"
application.register("date-picker-mobile", DatePickerMobileController)

import DragAndDropGridController from "../../components/drag_and_drop_grid_controller.js"
application.register("drag-and-drop-grid", DragAndDropGridController)

import ImagesSliderController from "../../components/images_slider_controller.js"
application.register("images-slider", ImagesSliderController)

import ImageUploadController from "../../components/image_upload_component_controller.js"
application.register("image-upload", ImageUploadController)

import NumpadController from "../../components/numpad_controller.js"
application.register("numpad", NumpadController)

import PhotoUploadController from "../../components/photo_upload_controller.js"
application.register("photo-upload", PhotoUploadController)

import ScheduleOptionsController from "../../components/schedule_options_component_controller.js"
application.register("schedule-options", ScheduleOptionsController)

import SiteNavSubmenuController from "../../components/site_nav/site_nav_submenu_controller.js"
application.register("site-nav-submenu", SiteNavSubmenuController)

import SiteNavSearchController from "../../components/site_nav/site_nav_search_controller.js"
application.register("site-nav-search", SiteNavSearchController)

import ThemedSelectController from "../../components/themed/select_controller.js"
application.register("themed-select", ThemedSelectController)

import TooltipComponentController from "../../components/tooltip_component_controller.js"
application.register("tooltip-component", TooltipComponentController)

import ThemedSelectCountryController from "../../components/themed/select_country_controller.js"
application.register("themed-select-country", ThemedSelectCountryController)

import SliderController from "../../components/slider_controller.js"
application.register("slider", SliderController)

import PosDropdownMenuController from "../../components/pos_dropdown_menu_controller.js"
application.register("pos-dropdown-menu", PosDropdownMenuController)

import CardOptionsController from "../../components/card_options_controller.js"
application.register("card-options", CardOptionsController)
