import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="pos-product-maps"
export default class extends Controller {
  updateSortIndex (event) {
    const indexFromPage = event.params.indexFromPage
    const nbCols = event.params.nbCols
    const newIndex = indexFromPage + (parseInt(event.detail.newRow) === 1 ? 0 : nbCols) +
                                     parseInt(event.detail.newCol)
    event.currentTarget.querySelector("[name*='[sort_index]']").value = newIndex

    // we submit the form only for the target change
    if (event.detail.type === 'target') this.element.requestSubmit()
  }
}
