import { Controller } from '@hotwired/stimulus'
import { computePosition, offset, flip, shift, arrow } from '@floating-ui/dom'

export default class extends Controller {
  static targets = ['template']
  static values = { initiallyShown: Boolean, placement: String }

  connect () {
    if (this.initiallyShownValue) {
      this.show()
    }
  }

  show () {
    if (this.tooltip) {
      return
    }

    this.tooltip = this.templateTarget.content.firstElementChild.cloneNode(true)
    this.tooltip.dataset.turboTemporary = true
    document.body.appendChild(this.tooltip)

    const tooltip = this.tooltip
    const arrowElement = tooltip.querySelector('.tooltip-arrow')
    computePosition(this.element, tooltip, {
      placement: this.placementValue || 'bottom-top',
      middleware: [offset(10), flip(), shift({ padding: 6 }), arrow({ element: arrowElement })]
    }).then(({ x, y, placement, middlewareData }) => {
      Object.assign(tooltip.style, { left: `${x}px`, top: `${y}px` })

      const { x: arrowX, y: arrowY } = middlewareData.arrow

      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right'
      }[placement.split('-')[0]]

      const arrowOffset = 5 // Half of the arrow size (10px / 2)

      if (placement.startsWith('top') || placement.startsWith('bottom')) {
        arrowElement.style.left = `calc(50% - ${arrowOffset}px)`
      } else if (placement.startsWith('left') || placement.startsWith('right')) {
        arrowElement.style.top = `calc(50% - ${arrowOffset}px)`
      }

      Object.assign(arrowElement.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: '-4px'
      })
    })
  }

  hide (event) {
    if (this.tooltip) {
      this.tooltip.remove()
      delete this.tooltip
    }
  }
}
