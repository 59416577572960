import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="data-attribute"
export default class extends Controller {
  static values = {
    name: String
  }

  set ({ params: { value } }) {
    this.element.dataset[this.nameValue] = value
  }

  toggle ({ params: { value } }) {
    if (this.element.dataset[this.nameValue]) {
      delete this.element.dataset[this.nameValue]
    } else {
      this.element.dataset[this.nameValue] = value
    }
  }
}
