// inspired from @appsignal/stimulus package

export function installErrorHandler (appsignal, application) {
  const prevHandler = application.handleError
  application.handleError = function (error, message) {
    // we update the error name so that it is more explicit
    const newError = error.constructor(error.message)
    newError.name = `${error.name}: ${message}`

    const span = appsignal.createSpan(function (span) {
      // here we can add all the information that we need chaining setTags({ attr: text })
      return span.setTags({ framework: 'Stimulus', message })
        .setTags({ url: window.location.href })
        .setError(newError)
    })
    appsignal.send(span)
    if (prevHandler && typeof prevHandler === 'function') {
      prevHandler.apply(this, arguments)
    }
  }
}
