import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['none', 'options']

  toggle () {
    const isNoneChecked = this.noneTarget.checked

    this.optionsTargets.forEach((checkbox) => {
      checkbox.disabled = isNoneChecked
      checkbox.checked = false
    })
  }
}
