import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'decrease']
  static values = { min: Number, debounce: Boolean }

  connect () {
    this.updateState()
    this.debounceTimer = null
  }

  selectAll (event) {
    setTimeout(() => event.target.select(), 50)
  }

  increase (event) {
    event.preventDefault()
    this.#change(1)
  }

  decrease (event) {
    event.preventDefault()
    this.#change(-1)
  }

  updateState () {
    if (!this.hasMinValue || !this.hasDecreaseTarget) {
      return
    }

    this.decreaseTarget.disabled =
      this.inputTarget.disabled || parseInt(this.inputTarget.value) <= this.minValue
  }

  dispatchNumberInputChange () {
    if (this.debounceValue) {
      // Debounce logic
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.dispatch('changed')
      }, 300) // Adjust debounce time as needed
    } else {
      // Dispatch immediately if debounce is not enabled
      this.dispatch('changed')
    }
  }

  #change (delta) {
    let currentValue = parseInt(this.inputTarget.value || 0)
    currentValue += delta
    this.inputTarget.value = Math.max(currentValue, this.hasMinValue ? this.minValue : 0).toString()
    this.updateState()
  }
}
