import Appsignal from '@appsignal/javascript'
import { installErrorHandler } from './installErrorHandler'

let appsignal

if (process.env.environment === 'production') {
  appsignal = new Appsignal({
    key: '4f4de222-a890-447d-8235-b907c8a0dd83'
  })
}

export { appsignal, installErrorHandler }
